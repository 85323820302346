import React from "react";

import "./WishesScreen.css";

import weeding_pair from "../../../images/weeding_pair.jpeg";
import { width, height } from "../../contants";

const WishesScreen: React.FC = () => {
  return (
    <div
      className='WishesContainer'
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        paddingBottom: height * 0.1,
      }}
    >
      <span style={{ fontSize: 36, margin: "20% 0 10% 0" }}>Пожелания</span>
      <span
        style={{
          fontSize: 20,
          fontFamily: "Inter, sans-serif",
          fontWeight: 700,
        }}
      >
        Дорогой Гость!
      </span>
      <div style={{ margin: "5%" }}>
        <span
          style={{
            fontSize: 16,
            fontFamily: "Inter, sans-serif",
          }}
        >
          Мы просим вас воздержаться от дарения нам цветов, так как после
          свадьбы у нас будет много забот и дел, и мы не сможем насладиться их
          красотой в полной мере.
        </span>
      </div>
      <div style={{ margin: "5%" }}>
        <span
          style={{
            fontSize: 16,
            fontFamily: "Inter, sans-serif",
          }}
        >
          Однако, если вы хотите выразить свою радость и любовь, мы будем
          благодарны за бутылку вина, вашу любимую книгу или виниловую пластинку
          для нашей коллекции. Такой подарок будет напоминать нам о вас и этом
          замечательном вечере!
        </span>
      </div>
      <span
        style={{
          fontSize: 20,
          fontWeight: 700,
          fontFamily: "Playfair Display",
          margin: "5%",
        }}
      >
        По любым вопросам можете обращаться к нашему организатору:
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "solid",
          borderWidth: 1,
          borderColor: "#000000",
          borderRadius: 20,
          width: "65%",
          alignSelf: "center",
          height: height * 0.1,
          marginTop: "5%",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ fontSize: 18, fontFamily: "Playfair Display" }}>
          +7 (918) 492-86-45
        </span>
        <span
          style={{
            fontSize: 18,
            fontFamily: "Playfair Display",
            fontWeight: 500,
          }}
        >
          Шульженко Галина
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "solid",
          borderWidth: 1,
          borderColor: "#000000",
          borderRadius: 20,
          width: "65%",
          alignSelf: "center",
          height: height * 0.1,
          marginTop: "3%",
          marginBottom: "2%",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          style={{
            fontSize: 22,
            fontFamily: "Playfair Display",
            fontWeight: 500,
            color: "#664141",
            position: "relative",
          }}
          href='https://www.instagram.com/shuljenko_wedding'
        >
          Shuljenko_wedding
        </a>
      </div>
      <div style={{ width: width, display: "flex" }}>
        <img
          width={width * 0.9}
          height={width * 0.9}
          src={weeding_pair}
          style={{ objectFit: "contain", marginTop: -width * 0.55 }}
          alt='weeding pair'
        />
      </div>
    </div>
  );
};

export default WishesScreen;
