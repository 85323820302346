import React, { useCallback, useLayoutEffect, useState } from "react";
import "./App.css";

import WelcomeScreen from "./components/screens/WelcomeScreen";
import ConfirmationScreen from "./components/screens/ConfirmationScreen";
import ProgramScreen from "./components/screens/ProgramScreen";
import WishesScreen from "./components/screens/WishesScreen";
import DressCodeScreen from "./components/screens/DressCode/DressCode";

function App() {
  const [confirmed, setConfirmed] = useState(false);
  const handleConfirm = useCallback(() => {
    window.scrollTo(0, 0);
    setConfirmed(true);
  }, []);

  useLayoutEffect(() => {
    // @ts-ignore
    const tg = window?.Telegram?.WebApp;
    if (tg && !tg.isExpanded) {
      tg.expand();
    }
  });
  return (
    <div className='App'>
      {confirmed ? (
        <>
          <ProgramScreen />
          <WishesScreen />
          <DressCodeScreen />
        </>
      ) : (
        <>
          <WelcomeScreen />
          <ConfirmationScreen handleConfirm={handleConfirm} />
        </>
      )}
    </div>
  );
}

export default App;
