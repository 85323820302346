import * as React from "react";
import "./ConfirmationScreen.css";

import { motion } from "framer-motion";
import { height } from "../../contants";

interface ConfirmationScreenProps {
  handleConfirm: () => void;
}

const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({
  handleConfirm,
}) => {
  const [clickConfirm, setClickConfirm] = React.useState(false);
  const [clickDecline, setClickDecline] = React.useState(false);
  let declineButtonPosition = { x: 0, y: 0 };

  const setNewDeclineButtonPosition = (e: any) => {
    if (!clickDecline) {
      setClickDecline(true);
    }
    const multiplier = Math.random() > 0.5 ? -1 : 1;
    const shift = {
      x: Math.random() * 100 * multiplier,
      y: Math.random() * 100 * multiplier,
    };
    declineButtonPosition = {
      x: declineButtonPosition.x + shift.x,
      y: declineButtonPosition.y + shift.y,
    };
    console.log("declineButton.current");
    e.target.style.transform = `translate(${declineButtonPosition.x}px,${declineButtonPosition.y}px)`;
  };
  return (
    <div className='ConfirmationContainer' style={{ height: height }}>
      <div
        className='WelcomeNameContainer'
        style={{ flexDirection: "row", fontSize: 38 }}
      >
        <span>Дорогой Гость</span>
        <motion.div
          style={{ marginLeft: "1%" }}
          initial={{ scale: 0, rotate: 180 }}
          whileInView={{
            scale: 1,
            rotate: 15,
            transition: {
              type: "spring",
              stiffness: 260,
              damping: 20,
            },
          }}
        >
          !
        </motion.div>
      </div>
      <div className='ConfirmationTextContainer'>
        <span className='ConfirmationText'>
          Мы счастливы пригласить вас на нашу свадьбу, которая состоится 7
          сентября в Геленджике
        </span>
        <span className='ConfirmationText'>
          Этот день будет запоминающимся и особенным для нас, и мы будем рады
          разделить его с вами!
        </span>
        <span className='ConfirmationText'>С любовью, Роберт и Людмила</span>
      </div>
      <div className='ConfirmationButtonsContainer'>
        <motion.div whileHover={{ scale: 1.07 }} whileTap={{ scale: 0.9 }}>
          <button
            style={{
              borderColor: "rgba(92, 13, 93, 1)",
              color: clickConfirm ? "#fff" : "#994d96",
              backgroundColor: clickConfirm ? "#994d96" : "",
            }}
            onTouchStart={() => setClickConfirm(true)}
            onTouchEnd={() => setClickConfirm(false)}
            onClick={handleConfirm}
          >
            Подтвердить Присутствие
          </button>
        </motion.div>
        <motion.div whileHover={{ scale: 1.07 }} whileTap={{ scale: 0.9 }}>
          <button
            className='Decline'
            onTouchStart={(e) => setNewDeclineButtonPosition(e)}
          >
            {clickDecline
              ? "Не получится Ждем Вас :)"
              : "Отклонить Приглашение"}
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default ConfirmationScreen;
