import React from "react";

import style_img_1 from "../../../images/old_money/1.jpg";
import style_img_2 from "../../../images/old_money/2.jpg";
import style_img_3 from "../../../images/old_money/3.jpg";
import style_img_4 from "../../../images/old_money/4.jpg";
import style_img_5 from "../../../images/old_money/5.jpg";
import style_img_6 from "../../../images/old_money/6.jpg";
import style_img_7 from "../../../images/old_money/7.jpg";
import style_img_8 from "../../../images/old_money/8.jpg";
import style_img_9 from "../../../images/old_money/9.jpg";

import "./DressCode.css";
import Timer from "../../Timer";
import { height, width } from "../../contants";

const DressCodeScreen: React.FC = () => {
  return (
    <div className='DressCodeContainer'>
      <span
        style={{
          marginTop: "10%",
          marginBottom: "5%",
          fontSize: 36,
          fontFamily: "Playfair Display",
        }}
      >
        Дресс - Код
      </span>
      <div style={{ margin: "5%", display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: 16, fontFamily: "Inter, sans-serif" }}>
          Для нас главное - ваше присутсвие.
        </span>
        <span style={{ fontSize: 16, fontFamily: "Inter, sans-serif" }}>
          Но мы будем рады, если в своих нарядах вы поддержите цветовую гамму и
          стилистику нашей свадьбы.
        </span>
      </div>
      <div style={{ margin: "3% 0 0 0" }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 500,
            fontFamily: "Inter, sans-serif",
          }}
        >
          Стиль - Old Money
        </span>
      </div>
      <div style={{ margin: "0 0 3% 0" }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 500,
            fontFamily: "Inter, sans-serif",
          }}
        >
          Цветовая Гамма - Светлые Тона
        </span>
      </div>
      <div style={{ margin: "5% 0", alignSelf: "center" }}>
        <div style={{ flexDirection: "row" }}>
          <img
            style={{ margin: "1%" }}
            src={style_img_1}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_2}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_3}
            width={width * 0.3}
            alt='old money'
          />
        </div>
        <div style={{ flexDirection: "row" }}>
          <img
            style={{ margin: "1%" }}
            src={style_img_4}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_5}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_6}
            width={width * 0.3}
            alt='old money'
          />
        </div>
        <div style={{ flexDirection: "row" }}>
          <img
            style={{ margin: "1%" }}
            src={style_img_7}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_8}
            width={width * 0.3}
            alt='old money'
          />
          <img
            style={{ margin: "1%" }}
            src={style_img_9}
            width={width * 0.3}
            alt='old money'
          />
        </div>
      </div>
      <div
        style={{
          margin: "5%",
          borderRadius: 10,
          borderWidth: 1,
          border: "solid",
          borderColor: "#664141",
          backgroundColor: "#ffffff5c",
          paddingTop: height * 0.01,
        }}
      >
        <span
          style={{
            fontSize: 20,
            marginTop: height * 0.01,
            fontWeight: 400,
            fontFamily: "Playfair Display",
            textAlign: "center",
          }}
        >
          До Торжества Осталось
        </span>
        <Timer />
      </div>
    </div>
  );
};

export default DressCodeScreen;
