import { DAY, HOUR, MIN, SEC } from "./constants";
import { WEEDING_DATE } from "../../constants";

export const getRemainTime = (date: Date): [number, number, number, number] => {
  const diff: number = +WEEDING_DATE - +date;
  const daysDiff = (diff / DAY) | 0;
  const hoursDiff = ((diff - daysDiff * DAY) / HOUR) | 0;
  const minutesDiff = ((diff - daysDiff * DAY - hoursDiff * HOUR) / MIN) | 0;
  const secondsDiff =
    ((diff - daysDiff * DAY - hoursDiff * HOUR - minutesDiff * MIN) / SEC) | 0;
  return [daysDiff, hoursDiff, minutesDiff, secondsDiff];
};
