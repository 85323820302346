import React, { useEffect, useState } from "react";
import "./Timer.css";
import { motion } from "framer-motion";
import { TIMER_TITLES } from "./constants";
import { getRemainTime } from "./utils";

interface TimerDigitProps {
  digit: number;
  title: string;
}

const TimerDigit: React.FC<TimerDigitProps> = ({ digit, title }) => {
  return (
    <motion.div
      initial={{ scale: 0, rotate: 180 }}
      whileHover={{ scale: 1.1 }}
      whileInView={{
        scale: 1,
        rotate: 0,
        transition: { type: "spring", stiffness: 260, damping: 20 },
      }}
      viewport={{ once: false }}
      className='TimerDigitContainer'
    >
      <div className='TimerDigit'>
        <time>{digit}</time>
      </div>
      <span className='TimerText'>{title}</span>
    </motion.div>
  );
};

const Timer = () => {
  const [remainTime, setRemainTime] = useState([0, 0, 0, 0]);

  useEffect(() => {
    const id = setInterval(
      () => setRemainTime(getRemainTime(new Date())),
      1000
    );
    return () => clearInterval(id);
  }, []);

  return (
    <div className='Timer'>
      {remainTime.map((item, index) => (
        <TimerDigit
          key={`timer-${index}`}
          digit={item}
          title={TIMER_TITLES[index]}
        />
      ))}
    </div>
  );
};

export default Timer;
