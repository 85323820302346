import React, { useState } from "react";
import "./ProgramScreen.css";

import { motion } from "framer-motion";

import { Divider } from "../../Divider";
import { height, width } from "../../contants";

interface ProgramScreenProps {}

interface ItemProps {
  title: string;
  Icon: any;
  time: string;
  backTitle: string;
  backDescription: string[];
}

const items: ItemProps[] = [
  {
    title: "Выездная Церемония",
    Icon: () => (
      <svg
        width='150'
        height='150'
        viewBox='0 0 60 81'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M43.98 24.228L48.984 11.684C49.152 11.264 49.11 10.797 48.894 10.406L48.903 10.401L43.134 0H35.079C35.076 0 35.074 0 35.071 0H16.48L10.437 10.374L10.442 10.377C10.196 10.799 10.165 11.314 10.372 11.76L16.121 24.156C6.549 29.169 0 39.195 0 50.728C0 67.259 13.449 80.708 29.98 80.708C46.511 80.708 59.96 67.259 59.96 50.728C59.961 39.251 53.475 29.265 43.98 24.228ZM41.257 22.956C39.392 22.196 37.434 21.62 35.405 21.248L38.461 12.628H45.377L41.257 22.956ZM27.378 20.867L24.453 12.628H35.279L32.364 20.851C31.577 20.789 30.784 20.748 29.981 20.748C29.103 20.747 28.236 20.793 27.378 20.867ZM33.933 2.999L35.505 9.628H24.227L25.802 2.999H33.933ZM45.044 9.628H38.588L37.016 2.999H41.368L45.044 9.628ZM18.205 2.999H22.717L21.143 9.628H14.343L18.205 2.999ZM14.082 12.628H21.269L24.342 21.286C22.44 21.65 20.603 22.194 18.846 22.899L14.082 12.628ZM29.98 77.708C15.103 77.708 3 65.604 3 50.728C3 35.852 15.103 23.748 29.98 23.748C44.857 23.748 56.96 35.852 56.96 50.728C56.96 65.604 44.857 77.708 29.98 77.708Z'
          fill='#664141'
        />
        <path
          d='M29.9845 28.774C17.8745 28.774 8.02246 38.623 8.02246 50.728C8.02246 62.838 17.8745 72.69 29.9845 72.69C42.0925 72.69 51.9425 62.838 51.9425 50.728C51.9425 38.622 42.0925 28.774 29.9845 28.774ZM29.9845 69.69C19.5285 69.69 11.0225 61.184 11.0225 50.728C11.0225 40.277 19.5285 31.774 29.9845 31.774C40.4385 31.774 48.9425 40.277 48.9425 50.728C48.9425 61.184 40.4385 69.69 29.9845 69.69Z'
          fill='#664141'
        />
      </svg>
    ),
    time: "16:00",
    backTitle: "Эпизод 1",
    backDescription: [
      "Выездная Церемония\nСбор Гостей - 16:00\nНачало - 17:00",
      "Место Проведения:\nГольф Клуб",
      "Адрес: Ул. Пограничная 44\nГолубая Бухта",
    ],
  },
  {
    title: "Ресторан Маринад",
    Icon: () => (
      <svg
        width='150'
        height='150'
        viewBox='0 0 101 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M45.1454 17.252L29.3664 12.674C28.9844 12.562 28.5744 12.609 28.2254 12.8C27.8764 12.991 27.6184 13.314 27.5084 13.696L18.0184 46.421C17.2944 48.909 17.5854 51.532 18.8364 53.806C19.8444 55.637 21.3824 57.054 23.2404 57.917L16.3224 81.77L9.97743 79.929C9.18043 79.696 8.35043 80.156 8.11943 80.951C7.88843 81.746 8.34643 82.579 9.14143 82.809L16.9124 85.064C16.9154 85.065 16.9184 85.067 16.9224 85.068C16.9254 85.069 16.9274 85.068 16.9304 85.069L24.7004 87.324C24.8404 87.365 24.9804 87.384 25.1184 87.384C25.7684 87.384 26.3684 86.958 26.5584 86.302C26.7894 85.506 26.3314 84.674 25.5364 84.444L19.2024 82.606L26.1204 58.756C26.5344 58.81 26.9504 58.845 27.3644 58.845C28.9794 58.845 30.5774 58.438 32.0324 57.637C34.3054 56.385 35.9554 54.324 36.6764 51.832L46.1684 19.111C46.3984 18.314 45.9404 17.482 45.1454 17.252ZM33.7944 50.996C33.2954 52.719 32.1564 54.142 30.5854 55.008C29.0164 55.871 27.2064 56.073 25.4844 55.575C25.4834 55.575 25.4824 55.574 25.4814 55.574C25.4804 55.574 25.4784 55.574 25.4774 55.573C23.7554 55.074 22.3304 53.933 21.4654 52.36C20.6004 50.789 20.4004 48.977 20.8994 47.258L22.9824 40.075H36.9634L33.7944 50.996ZM37.8334 37.075H23.8514L29.9704 15.972L42.8684 19.714L37.8334 37.075Z'
          fill='#664141'
        />
        <path
          d='M91.8775 80.619C91.6325 79.827 90.7945 79.386 90.0015 79.628L83.6905 81.578L76.3585 57.845C80.5835 55.779 82.8095 50.891 81.3825 46.267H81.3815L71.3185 13.712C71.0755 12.921 70.2355 12.479 69.4425 12.722L53.7465 17.573C53.3665 17.691 53.0495 17.954 52.8635 18.306C52.6765 18.658 52.6385 19.069 52.7575 19.449L62.8195 52.002C63.5855 54.479 65.2695 56.51 67.5625 57.721C68.9795 58.47 70.5245 58.85 72.0835 58.85C72.5535 58.85 73.0255 58.813 73.4955 58.743L80.8235 82.464L74.5255 84.41C73.7335 84.655 73.2905 85.495 73.5345 86.286C73.7335 86.931 74.3275 87.344 74.9685 87.344C75.1145 87.344 75.2635 87.323 75.4105 87.278L90.8875 82.496C91.6785 82.25 92.1215 81.41 91.8775 80.619ZM68.8965 16.031L72.6795 28.27H58.6235L56.0665 19.997L68.8965 16.031ZM68.9635 55.069C67.3785 54.232 66.2145 52.829 65.6855 51.116L59.5505 31.27H73.6055L78.5145 47.153C79.6045 50.681 77.6175 54.442 74.0865 55.542C74.0835 55.543 74.0805 55.543 74.0765 55.544C74.0725 55.545 74.0695 55.547 74.0665 55.548C72.3595 56.074 70.5455 55.906 68.9635 55.069Z'
          fill='#664141'
        />
      </svg>
    ),
    time: "18:00",
    backTitle: "Эпизод 2",
    backDescription: [
      'Праздничный Банкет\nВ Ресторане "Маринад"',
      "По Адресу:\nГ.Геленджик, Ул. Шмидта 1",
    ],
  },
];

const Item: React.FC<ItemProps> = ({
  title,
  Icon,
  time,
  backTitle,
  backDescription,
}) => {
  const [showBack, setShowBack] = useState(false);
  const toggleProgram = () => setShowBack(!showBack);
  return (
    <>
      <motion.div
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.05 }}
        onTap={toggleProgram}
        whileTap={{ scaleX: 0.3 }}
        whileInView={{
          transition: { delay: 2 },
          scaleX: [1, 0.7, 1, 0.7, 1],
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          padding: "10%",
        }}
      >
        <motion.div
          initial={{ y: 200 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
              type: "spring",
              stiffness: 260,
              damping: 18,
            },
          }}
          viewport={{ once: true }}
          style={{
            border: "solid",
            borderRadius: 30,
            backgroundColor: "white",
            width: width * 0.6,
            height: width * 0.6,
            pointerEvents: "none",
          }}
        >
          {showBack ? (
            <div
              style={{
                marginTop: height * 0.01,
                marginBottom: height * 0.03,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  fontFamily: "Inter, sans-serif",
                  marginBottom: height * 0.01,
                }}
              >
                {backTitle}
              </span>
              {backDescription.map((item, index) => {
                const items = item.split("\n");
                return (
                  <div
                    key={`back-desc-${index}`}
                    style={{
                      margin: "0 5% 5% 5%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {items.map((el, index) => (
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          fontFamily: "Inter, sans-serif",
                          textAlign: "center",
                        }}
                      >
                        {el}
                      </span>
                    ))}
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                height: "90%",
                width: "80%",
                margin: "5% 10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {title}
              </span>
              <Icon />
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 24,
                }}
              >
                {time}
              </span>
            </div>
          )}
        </motion.div>
      </motion.div>
      <Divider />
    </>
  );
};

const Items: React.FC = () => {
  return (
    <>
      {items.map((item, index) => (
        <Item
          key={`item-${index}`}
          title={item.title}
          Icon={item.Icon}
          time={item.time}
          backTitle={item.backTitle}
          backDescription={item.backDescription}
        />
      ))}
    </>
  );
};

const ProgramScreen: React.FC<ProgramScreenProps> = () => {
  return (
    <div
      className='ProgramScreenContainer'
      style={{ height: "100%", width: "100%" }}
    >
      <div style={{ marginTop: "10%", marginLeft: "20%", marginRight: "20%" }}>
        <span style={{ fontSize: 36 }}>Программа Торжества</span>
      </div>
      <Items />
    </div>
  );
};

export default ProgramScreen;
