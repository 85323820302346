export const Divider = () => (
  <div
    style={{
      borderRadius: 20,
      marginLeft: "10%",
      marginRight: "10%",
      borderWidth: 10,
      border: "solid",
    }}
  ></div>
);
