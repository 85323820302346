import * as React from "react";
import "./WelcomeScreen.css";

import { motion } from "framer-motion";
import moment from "moment";

import { WEEDING_DATE } from "../../../constants";
import { height } from "../../contants";

const WelcomeScreen: React.FC = () => {
  const dateDigits = moment(WEEDING_DATE).format("DD-MM-YY").split("-");
  return (
    <motion.div
      className='WelcomeContainer Background'
      style={{ minHeight: height }}
    >
      <div
        style={{
          display: "flex",
          marginLeft: "10%",
          alignSelf: "center",
          marginTop: height * 0.1,
          width: "80%",
          justifyContent: "space-between",
          letterSpacing: "26%",
        }}
      >
        {dateDigits.map((item, index) => {
          return (
            <span
              style={{
                fontWeight: 300,
                fontFamily: "Montserrat",
                fontSize: 24,
              }}
              key={`date-digit-${index}`}
            >
              {item}
            </span>
          );
        })}
      </div>
      <div className='WelcomeNameContainer'>
        <span>Р о б е р т</span>
        <motion.span
          initial={{ scale: 0, rotate: 180 }}
          whileInView={{
            scale: 1,
            rotate: 0,
            transition: {
              type: "spring",
              stiffness: 260,
              damping: 20,
            },
          }}
          style={{ fontSize: 30 }}
        >
          &
        </motion.span>
        <span>Л ю д м и л а</span>
      </div>
    </motion.div>
  );
};

export default WelcomeScreen;
